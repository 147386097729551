import * as React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import useSiteMetadata from '../../../hooks/useSiteMetadata';
import GlobalStyle from '../../../styles/GlobalStyle';
import { theme } from '../../../styles/theme';
// import Header from '../../organisms/Header';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';

const Contents = styled.div`
  height: 100%;
`;

const Main = styled.main``;

export interface LayoutProps {
  children: React.ReactNode;
  pageLocation?: string;
}

const Layout = ({ children, pageLocation }: LayoutProps) => {
  const { title } = useSiteMetadata();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Contents className="js-content">
        <Header pageLocation={pageLocation} />
        <Main>{children}</Main>
        <Footer siteTitle={title} />
      </Contents>
    </ThemeProvider>
  );
};

export default Layout;
