import chroma from 'chroma-js';
import { generateMedia } from 'styled-media-query';

export const pxToRem = (pxSize: number) => {
  return pxSize / 16;
};

const customMedia = generateMedia({
  sm: '450px',
  md: '768px',
  lg: '1170px',
  xl: '1440px',
});

export const vars = {
  fontSizes: [
    `${pxToRem(10)}rem`, // [0]
    `${pxToRem(12)}rem`, // [1]
    `${pxToRem(14)}rem`, // [2]
    `${pxToRem(16)}rem`, // [3]
    `${pxToRem(20)}rem`, // [4]
    `${pxToRem(24)}rem`, // [5]
    `${pxToRem(32)}rem`, // [6]
    `${pxToRem(48)}rem`, // [7]
    `${pxToRem(60)}rem`, // [8]
  ],
  fonts: {
    ja:
      "'Yu Gothic', YuGothic, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Arial, sans-serif",
    en: 'Poppins, sans-serif',
  },
  spaces: [
    `${pxToRem(4)}rem`, // [0]
    `${pxToRem(8)}rem`, // [1]
    `${pxToRem(12)}rem`, // [2]
    `${pxToRem(16)}rem`, // [3]
    `${pxToRem(20)}rem`, // [4]
    `${pxToRem(24)}rem`, // [5]
    `${pxToRem(32)}rem`, // [6]
    `${pxToRem(40)}rem`, // [7]
    `${pxToRem(80)}rem`, // [8]
  ],
  media: {
    sm: customMedia.greaterThan('sm'),
    md: customMedia.greaterThan('md'),
    lg: customMedia.greaterThan('lg'),
    xl: customMedia.greaterThan('xl'),
  },
  control: {
    height: `${pxToRem(40)}rem`,
  },
  transition: '0.4s ease-out',
};

export const mainColor = '#000';

export const theme = {
  ...vars,
  colors: {
    baseText: [
      '#fff', // [0]
    ],
    link: '#fff',
    bg: mainColor,
    logo: mainColor,
    input: {
      bg: 'transparent',
      border: '#8d8d8d',
      forcus: {
        border: '#fff',
      },
    },
    button: {
      bg: '#fff',
      border: '#fff',
      hover: {
        bg: chroma('#fff')
          .brighten(2)
          .alpha(0.8)
          .hex(),
      },
      forcus: {
        shadow: chroma('#fff')
          .alpha(0.3)
          .hex(),
      },
    },
  },
};
