import * as React from 'react';
import styled, { css } from 'styled-components';

const en = css`
  font-family: ${({ theme }) => theme.fonts.en};
  letter-spacing: 0.02em;
  text-indent: 0.01em;
`;

const ja = css`
  font-family: ${({ theme }) => theme.fonts.ja};
`;

const P = styled.p<PProps>`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spaces[7]};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: 1.8;

  ${({ theme }) => theme.media.sm`
    line-height: 2;
  `}

  ${({ lang }) => (lang === 'en' ? en : ja)}
  ${({ justify }) =>
    justify &&
    css`
      text-align: justify;
    `}

  ${props =>
    props.last &&
    css`
      margin-bottom: 0;
    `}
`;

export interface PProps {
  children: React.ReactNode;
  lang?: 'en' | 'ja';
  justify?: boolean;
  last?: boolean;
}

export default P;
