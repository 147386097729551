import styled from 'styled-components';

const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '640px')};
  padding: ${({ theme }) => theme.spaces[5]};
  padding-top: 0;

  ${({ theme }) => theme.media.sm`
    padding: ${theme.spaces[5]};
  `};
`;
export interface ContainerProps {
  fullWidth?: boolean;
}

export default Container;
