import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  position: relative;
  padding: 32px;

  ${({ theme }) => theme.media.sm`
    padding: 48px;
  `};
`;

const CopyRight = styled.p`
  font-family: ${({ theme }) => theme.fonts.en};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  margin: 0;
`;

interface FooterProps {
  siteTitle: string;
}

const Footer = ({ siteTitle }: FooterProps) => {
  return (
    <Wrapper>
      <CopyRight>
        &copy;Copyrights&nbsp;
        {new Date().getFullYear()}
        &nbsp;
        {siteTitle}
        &nbsp; All rights reserved.
      </CopyRight>
    </Wrapper>
  );
};

export default Footer;
