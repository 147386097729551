import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';

const Svg = styled.svg``;

const animDefault = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const duration = 0.7;
export const delay = 2;
const delayAfter = delay + duration * 1.5;

const DefaultStyle = css`
  stroke: #fff;
  stroke-width: 8.088;
  fill: none;
`;

const StyledDash = css`
  animation-duration: ${duration}s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: ${animDefault};
`;

const StyledDashDefault = css`
  ${StyledDash};
  stroke-dasharray: 112;
  stroke-dashoffset: 112;
`;

const StyledDashO = css`
  ${StyledDash};
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
`;

const Path1 = styled.path<LogoProps>`
  ${DefaultStyle};
  ${props => props.anim && StyledDashDefault}
  animation-delay: ${delay}s;
`;

const Path2 = styled.path<LogoProps>`
  ${DefaultStyle};
  ${props => props.anim && StyledDashDefault}
  animation-delay: ${delayAfter}s;
`;

const PathO1 = styled.path<LogoProps>`
  ${DefaultStyle};
  ${props => props.anim && StyledDashO}
  animation-delay: ${delay}s;
`;

const PathO2 = styled.path<LogoProps>`
  ${DefaultStyle};
  ${props => props.anim && StyledDashO}
  animation-delay: ${delayAfter}s;
`;

export interface LogoProps {
  anim?: boolean;
}

const Logo = ({ anim }: LogoProps) => {
  // React.useEffect(() => {
  //   const path = document.querySelector('#o1');
  //   const length = path.getTotalLength();
  //   console.log(length);
  // }, []);

  return (
    <Svg version="1.1" width="800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.8 85.1">
      <title>nou inc.</title>
      <Path1 anim={anim} d="M7.5,80.6V41.7c0,0-0.6-15,11.8-26.3C29.8,6,39.9,6.1,39.9,6.1" />
      <Path2 anim={anim} d="M48,5.9c0,0,10.1,0,20.6,9.4c6.2,5.6,11.8,15.2,11.8,26.3c0,24.8,0,38.9,0,38.9" />
      <PathO1
        anim={anim}
        d="M129,5.7c0,0-10.1,0-20.6,9.4c-6.2,5.6-11.8,15.2-11.8,26.3v1.1c0,11.1,5.6,20.8,11.8,26.3 c10.5,9.4,20.6,9.4,20.6,9.4"
      />
      <PathO2
        anim={anim}
        d="M137.1,78.3c0,0,10.1,0,20.6-9.4c6.2-5.6,11.8-15.2,11.8-26.3v-1.1c0-11.1-5.6-20.8-11.8-26.3c-10.5-9.4-20.6-9.4-20.6-9.4"
      />
      <Path1 anim={anim} d="M185.7,3.7v38.8c0,0-0.6,15,11.8,26.3c10.5,9.5,20.6,9.4,20.6,9.4" />
      <Path2 anim={anim} d="M226.2,78.3c0,0,10.1,0,20.6-9.4c6.2-5.6,11.8-15.2,11.8-26.3c0-24.8,0-38.9,0-38.9" />
    </Svg>
  );
};

export default Logo;
