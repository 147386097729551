import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import useSiteMetadata from '../../../hooks/useSiteMetadata';

interface SeoProps {
  pageDescription?: string;
  pageTitle: string;
  pageLocation?: string;
}

function Seo(props: SeoProps) {
  const data = useStaticQuery(graphql`
    query {
      ogp: file(relativePath: { eq: "ogp.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            originalImg
          }
        }
      }
    }
  `);

  const { title, description, siteUrl } = useSiteMetadata();
  const { pageDescription, pageTitle, pageLocation } = props;
  const metaDescription = pageDescription || description;
  const isHome = pageLocation === '/';

  return (
    <Helmet title={pageTitle} titleTemplate={isHome ? `${title} | %s` : `%s | ${title}`}>
      <html lang="ja" />
      <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
      <meta name="description" content={metaDescription} />
      <meta name="thumbnail" content={`${siteUrl}/icons/icon-512x512.png`} />
      <meta property="og:locale" content="ja" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={isHome ? `${title} | ${pageTitle}` : `${pageTitle} | ${title}`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={`${siteUrl}${pageLocation}`} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={`${data.ogp.childImageSharp.fluid.originalImg}`} />
      <meta property="og:image:secure_url" content={`${data.ogp.childImageSharp.fluid.originalImg}`} />
      <meta property="og:image:width" content="2400" />
      <meta property="og:image:height" content="1260" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={`${data.ogp.childImageSharp.fluid.originalImg}`} />
    </Helmet>
  );
}

export default Seo;
