import * as React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import Container from '../../atoms/Container';
import Title from '../../atoms/Title';

const Wrapper = styled.section`
  padding-top: ${({ theme }) => theme.spaces[7]};
  padding-bottom: ${({ theme }) => theme.spaces[7]};

  ${({ theme }) => theme.media.sm`
    padding-top: ${theme.spaces[8]};
    padding-bottom: ${theme.spaces[8]};
  `};
`;

interface ContentBlockProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const ContentBlock = ({ title, children, fullWidth }: ContentBlockProps) => (
  <Wrapper>
    <Container fullWidth={fullWidth ? true : false}>
      <Title>
        <Fade>{title}</Fade>
      </Title>
      <Fade>{children}</Fade>
    </Container>
  </Wrapper>
);

export default ContentBlock;
