import styled from 'styled-components';

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.en};
  font-size: ${({ theme }) => theme.fontSizes[7]};
  line-height: 1;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spaces[7]};
  letter-spacing: 0.02em;
  text-indent: 0.01em;
  position: relative;
  z-index: 10;

  ${({ theme }) => theme.media.sm`
    text-align: center;
    font-size: ${theme.fontSizes[8]};
  `}
`;

export default Title;
