import * as React from 'react';
import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import Logo, { delay } from '../../atoms/Logo';
import P from '../../atoms/P';

const animWhite = keyframes`
  0% {
    height: 0;
    top: calc(100% - 60px);
  }
  25% {
    height: 60px;
    top: calc(100% - 60px);
  }
  50% {
    height: 0;
    top: 100%;
  }
  100% {
    height: 0;
    top: 100%;
  }
`;

const Wrapper = styled.header<HeaderProps>`
  padding: 32px;
  text-align: center;
  height: ${({ pageLocation }) => (pageLocation ? '60%' : '100%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const StyledP = styled(P)<HeaderProps>`
  font-family: ${({ theme }) => theme.fonts.en};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: bold;
  margin: 0;
  position: absolute;
  top: calc(100% - 110px);
  display: flex;
  justify-content: center;
  height: 90px;
  letter-spacing: 0.05em;
  text-indent: 0.025em;

  ${({ borderAnim }) =>
    borderAnim &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        width: 1px;
        height: 0;
        top: calc(100% - 60px);
        background: #fff;
        animation-duration: 2.2s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: ${animWhite};
        animation-delay: ${delay}s;
      }
    `};
`;

const Message = styled(P)<HeaderProps>`
  margin-top: 36px;
  font-size: 10px;
`;

const LogoWrapper = styled.div`
  max-width: 315px;
`;

export interface HeaderProps {
  pageLocation?: string;
  borderAnim?: boolean;
}

const Header = ({ pageLocation }: HeaderProps) => {
  const isPageThanks = pageLocation === '/thanks/';
  const isPage404 = pageLocation === '/404/';

  return (
    <Wrapper>
      {isPageThanks || isPage404 ? (
        <>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Message lang="en">{isPageThanks ? 'THANK YOU.' : '404 / Page Not Found'}</Message>
          <StyledP>
            <Link to="/">&lt; BACK TO HOME</Link>
          </StyledP>
        </>
      ) : (
        <>
          <Logo anim />
          <StyledP borderAnim>PRODUCE & PLANNING</StyledP>
        </>
      )}
    </Wrapper>
  );
};

export default Header;
