import { css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const letterSpacing = css`
  letter-spacing: 0.1em;
  text-indent: 0.05em;
`;
